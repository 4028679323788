import React, { useRef, useState, useEffect } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import { useDispatch, useSelector } from 'react-redux';
import { fetchPartners } from '../rtk/slices/partners-slice';
import { fetchLogo } from '../rtk/slices/logo-slice';
import { fetchAbout } from '../rtk/slices/about-slice';
import { useTranslation } from 'react-i18next';

function About () {
    const { t, i18n } = useTranslation();

    // أضف المنطق لتحديد عدد العناصر المرئية بناءً على عرض الشاشة
    const slidesPerView = window.innerWidth >= 768 ? 4 : 2;
    const dispatch = useDispatch();
    const about = useSelector((state) => state.about.about);
    const partners = useSelector((state) => state.partners.partners);


    const about_en = about.map(ab_en => {
        if (i18n.language == "en") {
            return ({
                "img": ab_en.img,
                "text": ab_en.text_en
            })
        }
        return ab_en;
    })

    useEffect(() => {
        // fetch about
        dispatch(fetchAbout());
        dispatch(fetchPartners());
    }, [])
    return (
        <section className='about' id='about'>
            <div className="container">
                <h2 data-aos="fade-left" className="title">
                    <span>{i18n.language == "ar" ? "من نحنُ" : "About"}</span>
                    <p>{i18n.language == "ar" ? "من نحنُ" : "About"}</p>
                </h2>

                <div className="about-content">
                    {/* {about.map((content) => {
                        return (
                            <div key={content.id} className="box">
                                <img data-aos="fade-up"
                                    data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${content.img}`} alt="" />

                                <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                    {content.text}
                                </div>
                            </div>
                        )
                    })} */}

                    <div className="box">
                        {about_en.map((website) => {
                            return (
                                <>
                                    <img data-aos="fade-up"
                                        data-aos-anchor-placement="top-bottom" className='image' src={`${process.env.REACT_APP_PROJECTS_API}assest/imgs/${website.img}`} alt="" />

                                    <div data-aos="zoom-in" data-aos-duration="1500" className="text">
                                        <p className='priceP'>
                                            {website.text.split(',').map((price) => {
                                                return (
                                                    <>
                                                        <span className='priceSpan'>{price}</span><br />
                                                    </>
                                                )
                                            })}
                                        </p>
                                    </div>
                                </>
                            )
                        })}
                    </div>

                </div>
            </div>
        </section>
    )
}

export default About
